// tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

.app-logo {
  height: 38px;
  width: auto;
}
