@keyframes spWaveAf {
  from {
    transform: scale(0.25, 0.25);
    opacity: 0;
  }
  to {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
}
@keyframes spWaveBe {
  from {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  to {
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}

.component {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inlineComponent {
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60px;
  height: 60px;
  background-color: transparent;
  margin: auto;
  margin-bottom: 1rem;
}

.spinnerBg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border-radius: 50%;
}

.spinner {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30px;
  width: 30px;
  margin: -15px 0 0 -15px;
  border-radius: 50%;

  &:before,
  &:after {
    content: '';
    border: 2px solid black;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0px;
  }

  &:before {
    transform: scale(1, 1);
    opacity: 1;
    animation: spWaveBe 0.6s infinite linear;
  }

  &:after {
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 0;
    -webkit-animation: spWaveAf 0.6s infinite linear;
    animation: spWaveAf 0.6s infinite linear;
  }
}
